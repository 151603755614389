i[class^="cn-brandicon"] {
	align-items: center;
	display: inline-flex;
	font-style: normal;
	justify-content: center;
	overflow: hidden;
	//vertical-align: top;
	vertical-align: baseline;
}

i[class^="cn-brandicon"]::before {
	color: #fff; /* Fallback color for IE11 */
	color: var(--color, #fff); /* Use CSS variable to define color so it can be easily changed via JavaScript/jQuery. */
}

i.cn-brandicon-size-16 {
	border-radius: 3px;
	font-size: 8px;
	height: 16px;
	width: 16px;
}

i.cn-brandicon-size-24 {
	border-radius: 4px;
	font-size: 14px;
	height: 24px;
	width: 24px;
}

i.cn-brandicon-size-32 {
	border-radius: 5px;
	font-size: 20px;
	height: 32px;
	width: 32px;
}

i.cn-brandicon-size-48 {
	border-radius: 7px;
	font-size: 30px;
	height: 48px;
	width: 48px;
}

i.cn-brandicon-size-64 {
	border-radius: 9px;
	font-size: 44px;
	height: 64px;
	width: 64px;
}

i.cn-brandicon-angieslist {
	background-color: #309f3b;
}

i.cn-brandicon-delicious {
	background-color: #39f;
}

i.cn-brandicon-cdbaby {
	background-color: #000;
}

i.cn-brandicon-facebook {
	background-color: #3b5998;
}

i.cn-brandicon-flickr {
	background-color: #0063dc;
}

i.cn-brandicon-foursquare {
	background-color: #f94877;
}

i.cn-brandicon-goodreads {
	background-color: #630;
}

i.cn-brandicon-googleplus {
	background-color: #dc4e41;
}

i.cn-brandicon-googlescholar {
	background-color: #4885ed;
}

i.cn-brandicon-houzz {
	background-color: #7ac142;
}

i.cn-brandicon-imdb {
	background-color: #e6b91e;
}

i.cn-brandicon-instagram {
	background-color: #e4405f;
}

i.cn-brandicon-itunes {
	background-color: #ee4db7;
}

i.cn-brandicon-linked-in {
	background-color: #0077b5;
}

i.cn-brandicon-mixcloud {
	background-color: #314359;
}

i.cn-brandicon-myspace {
	background-color: #000;
}

i.cn-brandicon-odnoklassniki {
	background-color: #f4731c;
}

i.cn-brandicon-orcid {
	background-color: #a6ce39;
}

i.cn-brandicon-pinterest {
	background-color: #bd081c;
}

i.cn-brandicon-podcast {
	background-color: #93c;
}

i.cn-brandicon-researchgate {
	background-color: #0cb;
}

i.cn-brandicon-reverbnation {
	background-color: #e43526;
}

i.cn-brandicon-rss {
	background-color: #ffa500;
}

i.cn-brandicon-smugmug {
	background-color: #98c932;
}

i.cn-brandicon-soundcloud {
	background-color: #f30;
}

i.cn-brandicon-stitcher {
	background-color: #000;
}

i.cn-brandicon-technorati {
	background-color: #1ec11d;
}

i.cn-brandicon-telegram {
	background-color: #2ca5e0;
}

i.cn-brandicon-tiktok {
	background-color: #000;
}

i.cn-brandicon-tripadvisor {
	background-color: #00af87;
}

i.cn-brandicon-tumblr {
	background-color: #36465d;
}

i.cn-brandicon-twitter {
	background-color: #000;
}

i.cn-brandicon-vimeo {
	background-color: #1ab7ea;
}

i.cn-brandicon-vk {
	background-color: #6383a8;
}

i.cn-brandicon-whatsapp {
	background-color: #25d366;
}

i.cn-brandicon-yelp {
	background-color: #d32323;
}

i.cn-brandicon-x {
	background-color: #000;
}

i.cn-brandicon-youtube {
	background-color: #f00;
}
