#recent-content-block-recently-viewed {

	ul {
		padding: 0;

		li {
			//border: 3px solid blue;
			display: flex;
			padding: 0 0 20px;

			.cn-recently-viewed-image {
				align-self: flex-start;
				object-fit: contain;
				width: 100px;

				.cn-image-none,
				.cn-image-style {
					border-radius: 4px;
					background-color: #fff;
					border: 1px solid #e3e3e3;
					box-sizing: border-box;
					display: inline-block;
					line-height: 0;
					margin: 0 0 5px;
					max-width: 100%;
					padding: 5px;
					width: auto
				}

				.cn-image-none {
					line-height: normal;
				}
			}

			.cn-recently-viewed-details {
				flex: 1 1 auto;
				margin: 0 0 0 16px;
			}
		}
	}
}
